import React, { useEffect, useState } from "react";
import { useGetQuestionsCatalogueQuery } from "../questions/QuestionsApiSlice";
import TopicSelector from "../../components/dash-components/TopicSelector";
import {
  useGetChaptersQuery,
  useUpdateChapterMutation,
} from "./ChapterApiSlice";
import {
  IMCQCaseQuestion,
  IMCQQuestion,
} from "../../components/dash-components/Mcq/types";
import { useUploadImageMutation } from "../../app/api/apiSlice";
import StatementEditor from "../cases/components/StatementEditor";
import { uniqueId } from "lodash";
import QuestionListPreview from "../../components/dash-components/QuestionListPreview";
import { Button, Drawer, message } from "antd";
import MCQEditor from "../../components/dash-components/Mcq/McqEditor";
import {
  EditOutlined,
  LeftOutlined,
  RightOutlined,
  SaveOutlined,
  PlusCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import FilteredQuestions from "../questions/FilteredQuestions";

export interface ChapterData {
  id?: string;
  en_content: string;
  questionIds?: IMCQCaseQuestion[];
  title?: string;
  likes?: number;
  dislikes?: number;
  topic?: string;
  subtopic?: string;
  hi_content?: string;
  sequence?: string;
  isActive?: string;
}

const ShowChapters = () => {
  const [selectedFilter, setSelectedFilter] = useState<{
    topicId: string | null;
    subtopicId: string | null;
  }>({
    topicId: null,
    subtopicId: null,
  });
  const [selectedClass, setSelectedClass] = useState<string>();
  const [questions, setQuestions] = useState<ChapterData[]>([]);
  const [editingChapterIndex, setEditingChapterIndex] = useState<number>(-1);
  const [showQuestionsDrawer, setShowQuestionsDrawer] = useState(false);
  const [IseditingQuestions, setIsEditingQuestions] = useState<boolean>(false);
  const [editingQuestion, setEditingQuestion] = useState<IMCQQuestion>();
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [selectedQuestions, setSelectedQuestions] = useState<IMCQQuestion[]>(
    []
  );
  const { data: classData, isLoading: isClassDataLoading } =
    useGetQuestionsCatalogueQuery();

  const { data: questionList, refetch } = useGetChaptersQuery(
    {
      topic: selectedFilter.topicId || null,
      subtopic: selectedFilter.subtopicId || null,
    },
    {
      skip: !selectedFilter?.topicId || !selectedFilter?.subtopicId,
    }
  );

  const [uploadAsset] = useUploadImageMutation();
  const [updateChapter] = useUpdateChapterMutation();

  const updateFilter = (selection: any) => {
    const concepts = selection.concept || null;
    const classId = selection.classId || undefined;
    const topicId = selection.topicId || undefined;
    const subtopicId = selection.subtopicId || undefined;
    setSelectedFilter({ topicId, subtopicId });
    setSelectedClass(classId);
  };

  useEffect(() => {
    // Set questions only when questionList changes
    if (questionList) {
      setQuestions([...questionList]);
    }
  }, [questionList]);

  const handleEditStatement = (index: number) => {
    setEditingChapterIndex(index);
  };

  const handleAddOrDeleteQuestions = async (
    chapterIndex: number,
    updatedQuestionIds: any[]
  ) => {
    const updatedChapter = {
      ...questions[chapterIndex],
      questionIds: questions[chapterIndex]?.questionIds?.map(
        (question) => question.id
      ),
      // questionIds: updatedQuestionIds.map((question) => question.id),
    };

    try {
      await updateChapter({
        chapterId: updatedChapter.id!,
        chapterData: { questionIds: updatedChapter.questionIds },
      });

      // setQuestions((prev) =>
      //   prev.map((chapter, idx) => (idx === chapterIndex ? updatedChapter : chapter))
      // );
      refetch();
      message.success("Questions updated successfully");
      console.debug("Chapter updated successfully");
    } catch (error) {
      console.error("Failed to update chapter", error);
      message.error("Failed to update questions");
    }
  };

  const saveUpdatedChapter = async (
    chapterIndex: number,
    updatedContent: string[]
  ) => {
    // Use the first element of updatedContent as the chapter content
    const updatedChapter = {
      ...questions[chapterIndex],
      en_content: updatedContent[0],
    };

    try {
      await updateChapter({
        chapterId: updatedChapter.id!,
        chapterData: { en_content: updatedChapter.en_content },
      });

      // setQuestions((prev) =>
      //   prev.map((chapter, idx) => (idx === chapterIndex ? updatedChapter : chapter))
      // );
      refetch();
      message.success("Questions updated successfully");
      console.debug("Chapter updated successfully");
    } catch (error) {
      console.error("Failed to update chapter", error);
      message.error("Failed to update questions");
    }
  };

  const deleteChapterQuestion = (questionId?: string) => {
    if (!questions) throw new Error("Questions data not found");
    console.log("Deleting question with id:", questionId);
    setQuestions((prevQuestions) =>
      prevQuestions.map((chapter, idx) => {
        console.log("Chapter selected:", chapter);
        // Filter out the question with the specified id
        const updatedQuestionIds = chapter.questionIds
          ? chapter.questionIds.filter((q) => q.id !== questionId)
          : [];

        // Return updated chapter with modified questionIds
        return { ...chapter, questionIds: updatedQuestionIds };

        
      })
    );

    setIsEditingQuestions(false);
  };

  const openQuestionEditor = (newQuestion = false) => {
    if (newQuestion) {
      // Create an empty question with default values
      const newQuestionData: IMCQQuestion = {
        id: uniqueId(), // Assign a unique ID
        en_question: [], // Empty question text
        en_options: [], // Empty options
        topic: selectedFilter.topicId || undefined,
        subtopic: selectedFilter.subtopicId || undefined,
        allConceptTags: [],
        explaination: "",
        type: "mcq", // Default question type
        formatType: "FOUR_OPTIONS",
        status: "pending",
        meta: [],
      };
      // Set the editing question to the new one
      setEditingQuestion(newQuestionData);
    }
    setIsEditingQuestions(true);
  };

  const updateChapterQuestions = (questionsToUpdate: IMCQQuestion[]) => {
    if (!questions) throw new Error("Questions data not found");

    setQuestions((prevQuestions) =>
      prevQuestions.map((chapter, idx) => {
        if (idx === selectedIndex) {
          // Update or add each question in the questionsToUpdate array
          const updatedQuestionIds = chapter.questionIds
            ? [...chapter.questionIds] // Copy the existing questionIds array
            : [];

          questionsToUpdate.forEach((question) => {
            const questionWithId = {
              ...question,
              id: question.id || uniqueId(),
            };

            // Check if the question exists in updatedQuestionIds
            const questionIndex = updatedQuestionIds.findIndex(
              (q) => q.id === questionWithId.id
            );

            if (questionIndex !== -1) {
              // Update the existing question if found
              updatedQuestionIds[questionIndex] = questionWithId;
            } else {
              // Add new question if not found
              updatedQuestionIds.push(questionWithId);
            }
          });

          // Return updated chapter with modified questionIds
          return { ...chapter, questionIds: updatedQuestionIds };
        }
        return chapter; // Return chapter unmodified if it's not the selected one
      })
    );
    setSelectedQuestions([]);
    setIsEditingQuestions(false);
  };


  const uploadAudio = async (formData: FormData) => {
    const result = await uploadAsset({
      formData,
      prefix: "mcq-audio",
    }).unwrap();
    return result.url;
  };

  const uploadImage = async (formData: FormData) => {
    const result = await uploadAsset({
      formData,
      prefix: "mcq-image",
    }).unwrap();
    return result.url;
  };

  return (
    <div className="p-4">
      {classData && (
        <TopicSelector classes={classData} onChange={updateFilter} />
      )}

      <div className="mt-4">
        {questions.map((item, index) => (
          <div
            key={item.id}
            className="flex justify-between mb-6 border rounded p-2 mr-2"
            onClick={() => setEditingChapterIndex(index)}
          >
            <div className="w-full">
              <span className="text-lg">Chapter: {item?.title}</span>
              <div className="flex">
                <div className="w-2/3">
                  <StatementEditor
                    initialData={[item.en_content]}
                    onSave={(updatedContent) =>
                      saveUpdatedChapter(index, updatedContent)
                    }
                  />
                </div>
                <div className="w-1/3">
                  <QuestionListPreview
                    questions={item.questionIds || []}
                    // onEdit={openQuestionEditor}
                    onDelete={deleteChapterQuestion}
                    setEditingQuestion={setEditingQuestion}
                  />
                  <Button
                    size="large"
                    type="dashed"
                    icon={<PlusCircleOutlined />}
                    onClick={() => {
                      // openQuestionEditor(true);
                      setSelectedIndex(index);
                      setShowQuestionsDrawer(true);
                    }}
                    className="mt-4"
                  >
                    Add question
                  </Button>
                  <div>
                    <Button
                      onClick={() => {
                        handleAddOrDeleteQuestions(index, selectedQuestions);
                      }}
                      className="mt-4 mb-4"
                    >
                      Save Questions
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      {/* <Drawer
        title="Edit Questions"
        open={IseditingQuestions}
        width={"90%"}
        onClose={() => setIsEditingQuestions(false)}
        styles={{ header: { paddingBottom: 32 } }}
        destroyOnClose
      >
        <MCQEditor
          data={editingQuestion}
          onSave={updateChapterQuestion}
          uploadAudio={uploadAudio}
          uploadImage={uploadImage}
        />
      </Drawer> */}
      <Drawer
        title="Select Questions"
        placement="bottom" // Adjust placement as needed
        width={600} // Adjust width as needed
        onClose={() => setShowQuestionsDrawer(false)}
        open={showQuestionsDrawer}
        extra={
          <Button
            type="primary"
            onClick={() => {
              // Add your submit logic here
              // handleSubmitSelectedQuestions();
              updateChapterQuestions(selectedQuestions);
              setShowQuestionsDrawer(false);
            }}
          >
            Submit
          </Button>
        }
        height={500}
      >
        <FilteredQuestions
          selectedClass={selectedClass}
          selectedTopic={selectedFilter?.topicId}  
          selectedSubtopic={selectedFilter?.subtopicId}
          isSelect={true}
          selectedQuestions={selectedQuestions}
          setSelectedQuestions={setSelectedQuestions}
        />
      </Drawer>
    </div>
  );
};

export default ShowChapters;
